<template>
    <base-page>
        <titlu-pagina Titlu="Raport formulare" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :span='4'>
                            <el-form-item label='NumarInregistrare' >
                                <el-input v-model='Filters.NumarInregistrare' />
                            </el-form-item>
                        </el-col>                       
                        <el-col :md='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" :row-class-name="tableRowClassName">
            <el-table-column prop='IdIntrare' label='Numar inregistrare'></el-table-column>
            <el-table-column prop='NumeSolicitant' label='Solicitant'></el-table-column>
            <el-table-column prop='DataExpirare' label='Data expirare'></el-table-column>
            <el-table-column prop='DataExpirare' label='Data expirare'></el-table-column>
            <el-table-column prop='Stare' label='Stare'></el-table-column>
            <el-table-column prop='Status' label='Status'></el-table-column>            
            <el-table-column fixed="right" label="Actiuni" width="250px" >
                <template slot-scope="scope" >
                    <el-tooltip content="Vizualizare">
                        <el-button type="primary" icon="el-icon-view" circle />
                    </el-tooltip> 
                    <el-tooltip v-if="$has_right('export-raport-formulare')" content="Export Excel">
                        <el-button type="success" icon="el-icon-download" circle />
                    </el-tooltip>                   
                </template>
            </el-table-column>
        </el-table>
       
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />        
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "raport_formulare",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina     
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { functii_angajati: [], departamente: []},
            Filters: { NumarInregistrare: '' },                
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            OptiuniTipAngajat: [{ value: 'Propriu', label: 'Propriu'}, { value: 'Cooptat',label: 'Cooptat' }],
        }
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
            if (row.Status == 'trimis') {
            return 'danger-row';
            } else if (row.Status == 'primit') {
            return 'warning-row';
            }
            return 'success-row';
        },       
        async get_info(){
            var response                = await this.post("angajati/get_info" );
            this.Info.functii_angajati  = response.functii_angajati;
            this.Info.departamente      = response.departamente;
            this.refresh_info();
        },
        async refresh_info(){  
            var response        = await this.post("raport_formulare/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('raport_formulare');
        },
        reset(){
            this.Filters = { NumarInregistrare: '' }
            this.refresh_info();
        },        
        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }        
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" >

    .top50{
        margin-top: 20px;
    }
    .proper{
        margin-top: 20px;
        text-transform: 'capitalize';
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

    .el-table .warning-row {
        background: oldlace;
    }
    .el-table .success-row {
        background: #f0f9eb;
    }
    .el-table .danger-row {
        background: #f05b6c;
    }

</style>
